import { ReactElement } from "react";
import Failed from "./_images/failed.svg";

export function TransactionErrorContent({
  message,
  onDismiss,
}: {
  message: ReactElement | string;
  onDismiss?: () => void;
}) {
  return (
    <div className="flex flex-col items-center justify-center gap-6 min-h-44">
      <Failed />
      <div className="flex flex-col items-center justify-center gap-2 w-full">
        <div className="w-20 text-sm text-center text-black text-balance">
          <p>{message}</p>
        </div>
      </div>
    </div>
  );
}
