import { useCallback } from 'react'
import { ChainId, Currency } from '@pancakeswap/sdk'
import { Link, Modal, InjectedModalProps, ModalProps } from '@pancakeswap/uikit'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { getBlockExploreLink, getBlockExploreName } from '../../utils'
import Succeed from './_images/succeed.svg'
import Pending from './_images/pending.svg'

export function ConfirmationPendingContent({ pendingText }: { pendingText?: string }) {
  return (
    <div className="flex flex-col items-center justify-center gap-6 min-h-44">
      <div className="size-16">
        <Pending />
      </div>
      <div className="flex flex-col items-center justify-center gap-2">
        <div className="text-lg text-black">{pendingText || 'Waiting for confirmation...'}</div>
        <div className="text-xs text-gray-6"> Confirm this transaction in your wallet</div>
      </div>
    </div>
  )
}

export function TransactionSubmittedContent({
  chainId,
  hash,
}: {
  onDismiss: () => void
  hash: string | undefined
  chainId: ChainId
  currencyToAdd?: Currency | undefined
}) {
  return (
    <div className="flex flex-col items-center justify-center gap-6 min-h-44">
      <div className="size-16">
        <Succeed className="size-16" />
      </div>
      <div className="flex flex-col items-center justify-center gap-2">
        <div className="text-lg text-black">Transaction Submitted</div>
        {chainId && hash && (
          <Link external small href={getBlockExploreLink(hash, 'transaction', chainId)}>
            <span className="text-xs text-gray-6">{`View on ${getBlockExploreName(chainId)}`}</span>
          </Link>
        )}
      </div>
    </div>
  )
}

interface ConfirmationModalProps {
  title: string
  customOnDismiss?: () => void
  hash: string | undefined
  content: () => React.ReactNode
  attemptingTxn: boolean
  pendingText: string
  currencyToAdd?: Currency | undefined
}

const TransactionConfirmationModal: React.FC<
  React.PropsWithChildren<InjectedModalProps & ConfirmationModalProps & ModalProps>
> = ({ title, onDismiss, customOnDismiss, attemptingTxn, hash, pendingText, content, currencyToAdd, ...props }) => {
  const { chainId } = useActiveChainId()

  const handleDismiss = useCallback(() => {
    if (customOnDismiss) {
      customOnDismiss()
    }
    onDismiss?.()
  }, [customOnDismiss, onDismiss])

  if (!chainId) return null

  return (
    <Modal title={title} headerBackground="gradientCardHeader" {...props} onDismiss={handleDismiss}>
      {attemptingTxn ? (
        <ConfirmationPendingContent pendingText={pendingText} />
      ) : hash ? (
        <TransactionSubmittedContent
          chainId={chainId}
          hash={hash}
          onDismiss={handleDismiss}
          currencyToAdd={currencyToAdd}
        />
      ) : (
        content()
      )}
    </Modal>
  )
}

export default TransactionConfirmationModal
